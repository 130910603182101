:root {
  --main-bg-color: #13492f;
  --primary-color: #92b19f;
  --secondary-color: #92b19f;
  --accent-color: #d09632;
  --light-color: #d0dad5;
  --gray-color: #ddd;
  --black-color: #222;
  --split--color: #1515a3;
  --stand--color: #8b0000;
  --header-sm: 9rem;
  --header-lg: 18rem;
}

@font-face {
  font-family: Spectral;
  src: url("Spectral_SC_Medium.8625b8ec.ttf");
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.answer {
  color: var(--accent-color);
}

.answer-container {
  text-align: center;
  color: var(--primary-color);
  flex-direction: column;
  grid-area: 2 / 2;
  justify-content: center;
  place-self: center;
  align-items: center;
  height: 16.5rem;
  line-height: 1.5;
  display: flex;
}

article {
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}

body {
  background-color: var(--main-bg-color);
  -webkit-overflow-scrolling: touch;
  font-family: Spectral;
  font-size: 3rem;
  overflow-y: hidden;
}

.body-overflow-on {
  overflow-y: auto;
}

.button-container {
  grid-column: 2;
  justify-content: space-around;
  padding: 2.4rem;
  display: flex;
}

.btn {
  background-color: var(--primary-color);
  color: rgb(var(--gray-color));
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 500rem;
  font-family: inherit;
  font-size: 2rem;
  transition: all .3s;
  box-shadow: 0 .4rem .4rem #0003;
}

.btn--action {
  width: 10rem;
  height: 10rem;
}

.btn:hover {
  background-color: var(--accent-color);
  box-shadow: 0 .8rem .8rem #0003;
}

.btn:active, .btn--clicked {
  box-shadow: none;
  color: var(--black-color);
  background-color: var(--accent-color);
}

.btn--choice {
  border-radius: .8rem;
  width: 12rem;
  height: 5rem;
  margin-top: 2.4rem;
  display: block;
}

.deal-container {
  flex-direction: column;
  grid-area: 2 / 3;
  justify-content: space-around;
  place-self: center start;
  align-items: center;
  display: flex;
}

.dealer-container, .user-container {
  grid-column: 2;
  place-self: center;
  padding: 2.4rem;
}

html {
  width: 100%;
  font-size: 62.5%;
  position: relative;
}

header {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hidden {
  display: none;
}

.logo {
  justify-content: center;
  align-items: center;
  height: 15rem;
  margin-top: 2.4rem;
}

main {
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2.4rem;
  display: grid;
}

.next-container {
  grid-area: 2 / 1;
  place-self: center end;
}

.playing-card {
  height: 30rem;
}

.playing-card--user-2 {
  margin-right: .8rem;
}

.restart-container {
  place-self: center end;
}

.missed-title, .streak-title {
  color: var(--gray-color);
  text-decoration: underline;
}

.missed-container {
  justify-self: start;
}

.missed-container ul {
  color: var(--accent-color);
  max-height: 21rem;
  line-height: 1.5;
  list-style: none;
}

.strategy-card-container {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 3rem;
  display: flex;
}

.strategy-card-icon {
  color: var(--accent-color);
  height: 3.2rem;
}

.strategy-card--link {
  color: #000;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.strategy-card-icon--label {
  color: var(--accent-color);
  font-size: 2rem;
}

.streak-container {
  grid-column: 1;
  justify-self: end;
}

.streak-number {
  color: var(--accent-color);
}

@media (width <= 88em) {
  html {
    font-size: 56%;
  }

  main {
    grid-template-columns: 1fr 1.5fr 1fr;
  }

  .playing-card {
    height: 30rem;
  }
}

@media (width <= 66em) {
  html {
    font-size: 50%;
  }

  main {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .playing-card {
    height: 28rem;
  }
}

@media (width <= 50em) {
  html {
    font-size: 46%;
  }

  .playing-card--up-card, .playing-card--user-1 {
    z-index: 20;
    transform: translateX(-25%);
  }

  .playing-card--down-card, .playing-card--user-2 {
    z-index: 20;
    transform: translateX(25%);
  }
}

@media (width <= 45em) {
  .playing-card {
    height: 26rem;
  }

  main {
    grid-template-columns: 1fr 3fr 1fr;
  }
}

@media (width <= 37em) {
  main {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 1.2rem;
  }

  .answer-container {
    text-align: center;
    flex-direction: column;
    grid-area: 3 / 2 / auto / 4;
    justify-content: center;
    place-self: center;
    align-items: center;
    height: 17.5rem;
    line-height: 1.5;
    display: flex;
  }

  .button-container {
    grid-area: 5 / 1 / auto / 5;
    justify-content: space-around;
    gap: 1.6rem;
    padding: 2.4rem;
    display: flex;
  }

  .next-container {
    grid-area: 4 / 1;
    place-self: center;
  }

  .dealer-container, .user-container {
    grid-column: 2 / 4;
    place-self: center;
    padding: 2.4rem;
  }

  .dealer-container {
    grid-row: 2;
  }

  .user-container {
    grid-row: 4;
  }

  .strategy-card-container {
    grid-area: 4 / 4;
    justify-self: center;
    margin-left: 0;
  }

  .card-container--dealer, .card-container--user {
    display: flex;
  }

  .deal-container {
    flex-direction: column;
    grid-area: 2 / 4;
    justify-content: space-around;
    place-self: center;
    height: 100%;
    display: flex;
  }

  .streak-container {
    grid-area: 1 / 2 / auto / 4;
  }

  .missed-container {
    grid-area: 9 / 2 / auto / 4;
  }

  .streak-container, .missed-container {
    place-self: center;
  }

  .logo {
    height: 10rem;
  }

  .btn--choice {
    border-radius: .8rem;
    width: 8rem;
    height: 5rem;
    margin-top: 0;
    padding: 0;
    display: block;
  }
}

@media (width <= 30em) {
  main {
    margin-top: 0;
  }

  body {
    font-size: 2.5rem;
  }

  .btn {
    font-size: 2rem;
  }

  .logo {
    height: 7rem;
    margin: 1.2rem 0 0;
  }

  .btn--choice {
    width: 6.4rem;
    height: 3.6rem;
  }

  .answer-container {
    grid-column: 1 / 5;
    place-self: center;
    height: 14rem;
  }

  .playing-card {
    height: 16rem;
  }

  .dealer-container, .user-container {
    padding: 0;
  }

  .streak-title {
    padding-bottom: 1.2rem;
  }

  .btn--action {
    width: 8rem;
    height: 8rem;
  }
}

@media (width <= 20em) {
  .button-container {
    padding: 2.4rem 0;
  }
}

/*# sourceMappingURL=index.13b50497.css.map */
